export interface CountryIds {
  co: string;
  mx: string;
  br: string;
  cl: string;
}

export const COUNTRY_IDS: any = {
	co: "6",
	mx: "7",
	br: "8",
	cl: "10",
	ve: "11",
};
