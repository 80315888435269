import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "../../contexts/AppContext";
import TermsAll from "./TermsAll";

function Terms() {
	const { doc } = useParams();
	const { country } = useContext(AppContext);

	const termsByCountry = () => <TermsAll doc={doc} country={country} />;

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [doc]);

	return (
		<div data-testid="TermsScreen">
			{termsByCountry()}
		</div>
	);
}

export default Terms;
