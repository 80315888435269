import { useEffect, useState } from "react";
import Axios from "axios";
import SelectDocument from "../Components/SelectDocument";
import { OptionProps } from "../Components/SelectDocument/interface";
import { TermsProps } from "../Terms.interface";
import PdfViewer from "../../../components/PdfViewer";
import { COUNTRY_IDS } from "./constans";
import styles from "../Terms.module.css";

function TermsAll({ doc = "conditions", country }: TermsProps) {
	const [colombiaDocs, setColombiaDocs] = useState<OptionProps[]>([]);
	const [urlPDF, setUrlPDF] = useState<string>("");
	const [loading, setLoading] = useState<boolean>(false);
	const { REACT_APP_STORE_API } = process.env;
	const countryBR = country === "br";

	const getTermnAndCoditions = async () => {
		setLoading(true);
		Axios.get(`${REACT_APP_STORE_API}termsandconditions/${COUNTRY_IDS[country]}`)
			.then((resp: any) => {
				const formattedData: OptionProps[] = resp?.data.map((item: any) => ({
					value: item.id,
					name: item.title,
					pdfUrl: item.pdfUrl,
				}));
				if (doc === "privacy") {
					const validatePrivacy: OptionProps[] = formattedData.filter((item) => item?.name.toLowerCase().includes(countryBR ? "privacidade" : "privacidad"));
					setColombiaDocs(validatePrivacy);
					setUrlPDF(validatePrivacy.find((item) => String(item.value) === String(doc))?.pdfUrl ?? formattedData[0].pdfUrl ?? "");
				} else {
					const validateConditions: OptionProps[] = formattedData.filter((item) => !item?.name.toLowerCase().includes(countryBR ? "privacidade" : "privacidad"));
					setColombiaDocs(validateConditions);
					setUrlPDF(validateConditions.find((item) => String(item.value) === String(doc))?.pdfUrl ?? formattedData[0].pdfUrl ?? "");
				}
			})
			.catch(() => {})
			.finally(() => setLoading(false));
	};

	useEffect(() => {
		if (country && doc) getTermnAndCoditions();
	}, [doc, country]);

	return (
		<div className="container">
			<div className={styles.termsCols}>
				<SelectDocument value={doc} options={colombiaDocs} />
				<div className={styles.termsTextContainer}>
					<h1 className={styles.termsTitle}><b>{`${colombiaDocs.find((item) => String(item.value) === String(doc))?.name ?? colombiaDocs[0]?.name ?? ""}`}</b></h1>
					<div className="w-full mt-6">
						{loading ? (
							<div className="w-full p-5 text-center">Cargando....</div>
						) : (
							<PdfViewer pdfUrl={urlPDF ?? ""} />
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default TermsAll;
