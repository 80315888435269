interface ViewPDFProps {
    pdfUrl: string;
}

function PdfViewer({ pdfUrl }: ViewPDFProps) {
	return (
		<div className="shadow-md w-full h-screen">
			<iframe
				src={`${pdfUrl}#toolbar=0`}
				width="100%"
				height="100%"
				frameBorder="0"
				title="PDF Viewer"
			/>
		</div>
	);
}

export default PdfViewer;
